<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "app",
  components: {},
  data(){
    return{
      
    }
  }
};
</script>

<style>
  html,body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }
  @font-face {
    font-family: 'SourceHanSans';
    src: url('./assets/font/SOURCEHANSANSCN-NORMAL.OTF');
  }
  #app {
    height: 100%;
    font-family: "SourceHanSans";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
  }
  a {
    text-decoration: none;
  }
  h1,h2,h3,h4,h5,h6,p {
    margin: 0;
  }
  img{
    vertical-align: middle;
  }
</style>
