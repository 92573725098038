<template>
	<el-row class="main-bottom">
		<div class="main-bottom-conts" style="background-color: #1d1d1d;">
			<el-col class="item-box img-ph" :xs="{ span: 6 }" :sm="{ span: 6 }" :md="{ span: 5, offset: 2 }"
				:lg="{ span: 2, offset: 4 }">
				<li>
					<router-link to="/main"><a href="#">首页</a></router-link>
				</li>
				<li>
					<router-link to="/main/cooperate"><a href="#">合作案例</a></router-link>
				</li>
			</el-col>
			<el-col class="item-box img-ph" :xs="{ span: 6 }" :sm="{ span: 6 }" :md="{ span: 5, offset: 2 }"
				:lg="{ span: 2, offset: 4 }">
				<li>
					<router-link to="/main/process"><a href="#">开发流程</a></router-link>
				</li>
				<li>
					<router-link to="/main/honor"><a href="#">荣誉资质</a></router-link>
				</li>
			</el-col>
			<el-col class="item-box img-ph" :xs="{ span: 6 }" :sm="{ span: 6 }" :md="{ span: 5, offset: 2 }"
				:lg="{ span: 2, offset: 4 }">
				<li>
					<router-link to="/main/service"><a href="#">新闻中心</a></router-link>
				</li>
				<li>
					<router-link to="/main/aboutUS"><a href="#">关于我们</a></router-link>
				</li>
			</el-col>
			<el-col class="item-box img-ph" :xs="{ span: 6 }" :sm="{ span: 6 }" :md="{ span: 5, offset: 2 }"
				:lg="{ span: 2, offset: 4 }">
				<li>
					<router-link to="/main/contactUS"><a href="#">联系我们</a></router-link>
				</li>
			</el-col>
			
			<div class="img-pc" style="background: #10162E;width: 100%;height: 1px;"></div>
			
			<el-col class="item-box img-pc" :xs="{ span: 12 }" :sm="{ span: 6 }" :md="{ span: 5, offset: 2 }"
				:lg="{ span: 2, offset: 4 }">
				<li>
					<router-link to="/main"><a href="#">首页</a></router-link>
				</li>
				<li>
					<router-link to="/main/cooperate"><a href="#">合作案例</a></router-link>
				</li>
				<li>
					<router-link to="/main/process"><a href="#">开发流程</a></router-link>
				</li>
				<li>
					<router-link to="/main/honor"><a href="#">荣誉资质</a></router-link>
				</li>
				<li>
					<router-link to="/main/service"><a href="#">新闻中心</a></router-link>
				</li>
				<li>
					<router-link to="/main/aboutUS"><a href="#">关于我们</a></router-link>
				</li>
				<li>
					<router-link to="/main/contactUS"><a href="#">联系我们</a></router-link>
				</li>
			</el-col>
			<el-col class="item-box" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
				:lg="{ span: 8, offset: 0 }">
				<div style="display: flex;flex-direction: row;justify-content: center;">
					<img style="height:130px;" :src="require('@/assets/image/WeChaticon_hunan.png')" />
					<div style="display: flex;flex-direction: column;color: #fff;margin-left: 10px;margin-top: 10px;">
						<div style="font-size: 24px;">湖南德合</div>
						<div style="font-size: 16px;margin-top: 10px;">联系电话：15681251902</div>
						<div style="font-size: 16px;">联系地址：中国·长沙·新长海中心B1栋610</div>
					</div>
				</div>
			</el-col>
			<el-col class="item-box" :xs="{ span: 24 }" :sm="{ span: 15 }" :md="{ span: 10, offset: 2 }"
				:lg="{ span: 7, offset: 1 }">
				<div style="display: flex;flex-direction: row;justify-content: center;">
					<img style="height:130px;" :src="require('@/assets/image/WeChaticon_yunnan.png')" />
					<div style="display: flex;flex-direction: column;color: #fff;margin-left: 10px;margin-top: 10px;">
						<div style="font-size: 24px;">云南德合</div>
						<div style="font-size: 16px;margin-top: 10px;">联系电话：18468119031</div>
						<div style="font-size: 16px;">联系地址：云南省昆明市呈贡区置信银河广场A座22楼</div>
					</div>
				</div>
			</el-col>
		</div>
		<div style="background: #10162E;width: 100%;height: 1px;"></div>
		<div class="main-bottom-copyright">
			<p>版权所有@2022 湖南德合信息科技有限公司</p>
			<p>湖南省长沙市岳麓区新长海中心b1栋610</p>
			
			<div style="width:300px;margin:0 auto; padding:20px 0;">
				<a target="_blank" href="https://beian.miit.gov.cn/"
					style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
					<img :src="require('@/assets/image/icon_gh.png')" style="float:left;" />
					<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">湘ICP备19007210号-1</p>
				</a>
			</div>
		</div>
	</el-row>

</template>
<script>
	export default {
		data() {
			return {
				record: ''
			}
		},
		methods: {
			setRecord() {
				var curWwwPath = window.location.host;
				if (curWwwPath.indexOf('issdn') > -1) {
					this.record = '湘ICP备18023659号-1'
				} else if (curWwwPath.indexOf('sxzsdn') > -1) {
					this.record = '湘ICP备18023659号-2'
				} else if (curWwwPath.indexOf('sxzwan') > -1) {
					this.record = '湘ICP备18023659号-3'
				} else if (curWwwPath.indexOf('xspeeder') > -1) {
					this.record = '湘ICP备18023659号-4'
				} else if (curWwwPath.indexOf('dwcache') > -1) {
					this.record = '湘ICP备18023659号-5'
				} else if (curWwwPath.indexOf('netsxz') > -1) {
					this.record = '湘ICP备18023659号-6'
				} else if (curWwwPath.indexOf('sxzros') > -1) {
					this.record = '湘ICP备18023659号-7'
				} else if (curWwwPath.indexOf('i1608') > -1) {
					this.record = '湘ICP备18023659号-8'
				} else if (curWwwPath.indexOf('sxzrouter') > -1) {
					this.record = '湘ICP备18023659号-9'
				}
			}
		},
		created() {
			this.setRecord();
		}
	}
</script>
<style lang="scss" scoped>
	.contact-img {
		width: 100%;
	}

	.bom-css {
		background-color: #434343;
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.bom-box {
			display: flex;
			padding: 10px 0;
			align-items: center;
			text-align: center;

			img {
				max-width: 46px;
			}

			.title {
				color: #FFFFFF;
				margin-left: 5px;

				.title-cn {
					font-size: 28px;
					line-height: 42px;
				}

				.title-en {
					font-size: 15px;
					line-height: 22px;
					letter-spacing: 0.02em;
					text-transform: uppercase;
				}


			}
		}

		.txt-right {
			text-align: right;
			margin-top: 22px;

			.txt {
				font-size: 15px;
				line-height: 22px;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				color: #666666;
			}
		}

		.txt-bom {
			text-align: center;
			padding: 10px 0;
			font-size: 15px;
			letter-spacing: 0.02em;
			text-transform: uppercase;
			color: #666666;
		}
	}


	.main-bottom {
		width: 100%;

	}

	.main-bottom .main-bottom-conts {
		width: 100%;
		background-color: #282828;
		padding: 30px 10px 30px;
		box-sizing: border-box;
		overflow: hidden;
	}

	.main-bottom .main-bottom-conts .item-box {
		margin-bottom: 20px;
		// min-height: 60px;
	}

	.main-bottom .main-bottom-conts .title {
		font-size: 16px;
		color: #fff;
		line-height: 1;
		padding-bottom: 16px;
	}

	.main-bottom .main-bottom-conts .title.zh {
		padding-bottom: 12px;
	}

	.main-bottom .main-bottom-conts .title.en {
		font-size: 10px;
	}

	.main-bottom .main-bottom-conts li {
		list-style: none;
		line-height: 1.5;
		padding-bottom: 12px;
		font-size: 14px;
		color: #fff;
	}

	.main-bottom .main-bottom-conts li a {
		font-size: 14px;
		color: #fff;
	}

	.main-bottom .main-bottom-conts li a:hover {
		text-decoration: underline;
		color: #fff;
	}

	.main-bottom .main-bottom-conts .item-box.code {
		text-align: center;

		img {
			width: 115px;
		}
	}

	.main-bottom .main-bottom-conts .codeimg {
		vertical-align: middle;
		width: 115px;
	}

	.main-bottom .main-bottom-conts .codetext {
		font-size: 12px;
		color: #b3b3b3;
		line-height: 30px;
	}

	.main-bottom .main-bottom-copyright {
		width: 100%;
		background-color: #1d1d1d;
		font-size: 14px;
		padding-top: 10px;
		text-align: center;
		color: #fff;
	}

	.main-bottom .main-bottom-copyright p {
		display: inline-block;
		line-height: 40px;
		margin-right: 12px;

		&:last-child {
			margin-right: 0;
			display: block;
			text-align: center;
			line-height: 1;
			padding-bottom: 12px;

			a {
				display: inline-block;
				padding-left: 24px;
				background-size: 20px;
			}
		}
	}

	.main-bottom .main-bottom-copyright p a {
		line-height: 20px;
		color: #757575;

		&:hover {
			color: #ababab;
			text-decoration: underline;
		}
	}
	.img-ph{
		display: none;
	}

	@media screen and (max-width: 767px) {
		.img-ph{
			display: block;
			
			text-align: center;
			font-size: 20px;
		}
		.img-pc{
			display: none;
		}
		.main-bottom .main-bottom-conts .item-box.code {
			text-align: left;
		}

		.main-bottom .main-bottom-copyright p {
			display: block;
			line-height: 20px;
			margin-right: 0;
		}

		.bom-css {
			box-sizing: border-box;
			background-color: #434343;
			align-items: center;
			display: flex;
			flex-direction: column;
			justify-content: center;

			.bom-box {
				display: flex;
				flex-direction: column;
				padding: 10px 0 0;
				align-items: center;
				justify-content: center;

				img {
					max-width: 46px;
				}

				.title {
					color: #FFFFFF;
					text-align: center;

					.title-cn {
						font-size: 16px;
						line-height: 20px;
					}

					.title-en {
						font-size: 10px;
						line-height: 20px;
						letter-spacing: 0.02em;
						text-transform: uppercase;
					}
				}
			}

			.txt-right {
				text-align: center;
				margin: 0 0 5px 0;

				.txt {
					line-height: 18px;
					font-size: 10px;
					letter-spacing: 0.02em;
					text-transform: uppercase;
					color: #666666;
					display: flex;
					justify-content: center;

					.txt-2 {
						margin-left: 12px;
					}
				}
			}

			.txt-bom {
				text-align: center;
				padding: 5px 0 10px;
				font-size: 10px;
				line-height: 18px;
				letter-spacing: 0.02em;
				text-transform: uppercase;
				color: #666666;
			}
		}

		.contact-img {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 45px;
			object-fit: cover;
		}
	}
</style>
