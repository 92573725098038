<template>
	<div>
		<el-row id="t1" class="main-sidebar-nav">
			<el-col class="logo-box" style="" :xs={span:0} :sm={span:6} :md={span:5,offset:2} :lg={span:8,offset:2}>
				<img :src="logo">
			</el-col>
			<el-col class="hidden-sm-and-up" style="padding: 10px 0;" :xs={span:3}>
				<div @click="toBlue()">
					<img v-if="navflag" @click="navflag = !navflag" :src="open">
					<img v-else @click="navflag = !navflag" :src="close">
				</div>

			</el-col>
			<el-col :class="[this.navflag?'nav-hide':'']" :xs={span:24} :sm={span:18} :md={span:15} :lg={span:12}>
				<el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal">
					<el-menu-item class="main-size" index="/main">
						<div class="ph-li" @click="toNone">
							<div>首页</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/cooperate">
						<div class="ph-li" @click="toNone">
							<div>合作案例</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/process">
						<div class="ph-li" @click="toNone">
							<div>开发流程</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/honor">
						<div class="ph-li" @click="toNone">
							<div>荣誉资质</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/service">
						<div class="ph-li" @click="toNone">
							<div>新闻中心</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/aboutUS">
						<div class="ph-li" @click="toNone">
							<div>关于我们</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/contactUS">
						<div class="ph-li" @click="toNone">
							<div>联系我们</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
				</el-menu>
			</el-col>
		</el-row>
		<el-row id="t2" class="main-sidebar-nav">
			<el-col class="logo-box" style="" :xs={span:0} :sm={span:6} :md={span:5,offset:2} :lg={span:8,offset:2}>
				<img src="@/assets/image/logo1.png">
			</el-col>
			<el-col class="hidden-sm-and-up" style="padding: 10px 0;" :xs={span:3}>
				<img v-if="navflag" @click="navflag = !navflag" src="@/assets/image/menu_open.png">
				<img v-else @click="navflag = !navflag" src="@/assets/image/menu_close.png">
			</el-col>

			<el-col :class="[this.navflag?'nav-hide':'']" :xs={span:24} :sm={span:18} :md={span:15} :lg={span:12}>
				<el-menu :default-active="$route.path" router class="el-menu-demo" mode="horizontal">
					<el-menu-item class="main-size" index="/main">
						<div class="ph-li" @click="toNone">
							<div>首页</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/cooperate">
						<div class="ph-li" @click="toNone">
							<div>合作案例</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/process">
						<div class="ph-li" @click="toNone">
							<div>开发流程</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/honor">
						<div class="ph-li" @click="toNone">
							<div>荣誉资质</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/service">
						<div class="ph-li" @click="toNone">
							<div>新闻中心</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/aboutUS">
						<div class="ph-li" @click="toNone">
							<div>关于我们</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
					<el-menu-item class="main-size" index="/main/contactUS">
						<div class="ph-li" @click="toNone">
							<div>联系我们</div>
							<img src="@/assets/image/icon_right.png">
						</div>
					</el-menu-item>
				</el-menu>
			</el-col>
		</el-row>
	</div>


</template>
<script>
	export default {
		data() {
			return {
				logo: require('@/assets/image/logo.png'),
				open: require('@/assets/image/menu_open0.png'),
				close: require('@/assets/image/menu_close0.png'),
				navflag: true
			}
		},

		methods: {
			toNone(){
				this.navflag = !this.navflag
			},
			toBlue() {
				document.getElementById('main_top').style.backgroundColor = '#fff';
				document.getElementById('t1').style.display = 'none';
				document.getElementById('t2').style.display = 'block';
			},
		}

	}
</script>
<style lang="scss" scoped>
	#t2 {
		display: none;
	}

	.el-menu {
		background-color: unset;
		color: #fff !important;
		margin: 0 10px;
	}

	.main-size {
		font-size: 15px;
		text-align: center;
	}

	.main-sidebar-nav {
		height: 100%;
		overflow: hidden;

	}

	.main-sidebar-nav .el-menu--horizontal>.el-menu-item.is-active,
	.main-sidebar-nav .el-menu--horizontal .el-menu .el-menu-item.is-active {
		color: #fff !important;
		background-color: unset;
	}

	.main-sidebar-nav .el-menu.el-menu--horizontal {
		border-bottom: 0;
	}

	@media screen and (max-width: 767px) {
		.pc {
			opacity: 0;
			display: none;
		}

		.ph-li {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1px solid #F4F4F4;
		}

		.hidden-sm-and-up {
			width: 30%;
			height: 60px;
			position: relative;
			align-items: center;

			img {
				position: absolute;
				top: 15px;
				right: 10%;
				width: 30px;
				height: 30px;

			}
		}

		.main-sidebar-nav .logo-box {
			width: 70%;
			height: 60px;
			padding: 5px 0;
			justify-content: center;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.main-sidebar-nav .logo-box img {
			position: absolute;
			top: 0%;
			width: 80%;
			transform: translate(0px, 0%);
			left: 2%;
			padding: 5px 0;
		}


		.main-sidebar-nav .el-menu-demo li {
			width: 100%;
			text-align: left;
			color: #fff;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-menu-item.is-active,
		.main-sidebar-nav .el-menu--horizontal .el-menu .el-menu-item.is-active {
			color: #fff !important;
			background-color: unset;
			border-bottom: unset !important;
		}
	}

	@media screen and (min-width: 768px) {
		.main-sidebar-nav .logo-box {
			height: 60px;
			padding: 5px 0;
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
		}

		.main-sidebar-nav .logo-box img {
			position: absolute;
			width: 40%;
			padding: 5px 0;
		}

		.main-sidebar-nav .el-menu-demo li {
			width: 14%;
			text-align: center;
			color: #fff;

		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}
	}
</style>
<style lang="scss">
	.main-sidebar-nav .nav-hide {
		display: none;
	}

	.el-menu--popup {
		min-width: 120px !important;
	}

	@media screen and (max-width: 767px) {

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__title {
			height: 40px !important;
			line-height: 40px !important;
		}

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-submenu__title {
			padding: 0 10px !important;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}
	}

	@media screen and (min-width: 768px) {
		.main-sidebar-nav .nav-hide {
			display: block;
		}

		.main-sidebar-nav .el-menu-item,
		.main-sidebar-nav .el-submenu__title {
			padding: 0 !important;
		}

		.main-sidebar-nav .el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
			margin-left: 4px !important;
		}

		.el-menu--horizontal .el-menu .el-menu-item {
			padding: 0 10px !important;
		}
	}
</style>
